// This code is copied from harmony explorer
// https://github.com/harmony-one/explorer-v2-frontend/blob/master/src/utils/getAddress

// HRP is the human-readable part of Harmony bech32 addresses
const HRP = 'one'
const CHARSET = 'qpzry9x8gf2tvdw0s3jn54khce6mua7l'

const GENERATOR = [0x3b6a57b2, 0x26508e6d, 0x1ea119fa, 0x3d4233dd, 0x2a1462b3]

const polymod = (values: Buffer): number => {
  let chk = 1
  // tslint:disable-next-line
  for (let p = 0; p < values.length; ++p) {
    const top = chk >> 25
    chk = ((chk & 0x1ffffff) << 5) ^ values[p]
    for (let i = 0; i < 5; ++i) {
      if ((top >> i) & 1) {
        chk ^= GENERATOR[i]
      }
    }
  }
  return chk
}

const hrpExpand = (hrp: string): Buffer => {
  const ret = []
  let p
  for (p = 0; p < hrp.length; ++p) {
    ret.push(hrp.charCodeAt(p) >> 5)
  }
  ret.push(0)
  for (p = 0; p < hrp.length; ++p) {
    ret.push(hrp.charCodeAt(p) & 31)
  }
  return Buffer.from(ret)
}

const convertBits = (data: Buffer, fromWidth: number, toWidth: number, pad = true) => {
  let acc = 0
  let bits = 0
  const ret = []
  const maxv = (1 << toWidth) - 1
  // tslint:disable-next-line
  for (let p = 0; p < data.length; ++p) {
    const value = data[p]
    if (value < 0 || value >> fromWidth !== 0) {
      return null
    }
    acc = (acc << fromWidth) | value
    bits += fromWidth
    while (bits >= toWidth) {
      bits -= toWidth
      ret.push((acc >> bits) & maxv)
    }
  }

  if (pad) {
    if (bits > 0) {
      ret.push((acc << (toWidth - bits)) & maxv)
    }
  } else if (bits >= fromWidth || (acc << (toWidth - bits)) & maxv) {
    return null
  }

  return Buffer.from(ret)
}

const isKeyString = (keyString: string, lengh: number): boolean => {
  return !!keyString.replace('0x', '').match(`^[0-9a-fA-F]{${lengh}}$`)
}

const isAddress = (address: string): boolean => {
  return isKeyString(address, 40)
}

function createChecksum(hrp: string, data: Buffer) {
  const values = Buffer.concat([Buffer.from(hrpExpand(hrp)), data, Buffer.from([0, 0, 0, 0, 0, 0])])
  // var values = hrpExpand(hrp).concat(data).concat([0, 0, 0, 0, 0, 0]);
  const mod = polymod(values) ^ 1
  const ret = []
  for (let p = 0; p < 6; ++p) {
    ret.push((mod >> (5 * (5 - p))) & 31)
  }
  return Buffer.from(ret)
}

const bech32Encode = (hrp: string, data: Buffer) => {
  const combined = Buffer.concat([data, createChecksum(hrp, data)])
  let ret = hrp + '1'
  // tslint:disable-next-line
  for (let p = 0; p < combined.length; ++p) {
    ret += CHARSET.charAt(combined[p])
  }
  return ret
}

export const toBech32 = (address: string, useHRP: string = HRP): string => {
  if (!isAddress(address)) {
    console.log(address)
    throw new Error('Invalid address format.')
  }

  const addrBz = convertBits(Buffer.from(address.replace('0x', ''), 'hex'), 8, 5)

  if (addrBz === null) {
    throw new Error('Could not convert byte Buffer to 5-bit Buffer')
  }

  return bech32Encode(useHRP, addrBz)
}
